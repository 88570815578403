import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import useFileImport, { ColumnT } from '../useFileImport';
import * as XLSX from 'xlsx';
import { SheetIssues, Check, FieldValidationProps } from '@src/type';


const FieldValidationFile: React.FC<FieldValidationProps> = (props) => {
  const { context } = useFileImport();
  const [updatedColumns, setUpdatedColumns] = React.useState<Record<string, string>>({});
  const [passedCheckIds, setPassedCheckIds] = React.useState<Set<number>>(new Set());
  const [hasDefaultSheet, setHasDefaultSheet] = React.useState(false);
  const [forceUpdate, setForceUpdate] = React.useState(false);

  const issues = React.useMemo(() => {
    if (!context.workbook?.workbook) return {} as Record<string, SheetIssues>;
    return checkWorkbookForIssues(context.workbook.workbook);
  }, [context.workbook, forceUpdate]);

  const sheets: string[] = context.workbook?.workbook?.SheetNames || [];
  const hasMultipleSheets = sheets.length > 1;

  const checks = transformIssuesToChecks(
    issues[props.sheetName] || {
      missingHeaderColumns: [],
      headerNoDataColumns: [],
      emptyRows: [],
      emptyColumns: [],
      duplicatedColumns: [],
    },
    hasMultipleSheets,
    sheets,
    passedCheckIds,
    hasDefaultSheet
  );

  useEffect(() => {
    const currentCheckIds = new Set(checks.map((check) => check.id));
    setPassedCheckIds((prev) => new Set([...prev].filter((id) => currentCheckIds.has(id))));
  }, [checks]);

  useEffect(() => {
    if (checks.length === 0) {
      props.onAllChecksPassed?.(false);
    } else {
      const allPassed = checks.every((check) => passedCheckIds.has(check.id));
      props.onAllChecksPassed?.(!allPassed);
    }
  }, [checks, passedCheckIds, props.onAllChecksPassed]);

  const triggerIssueRecalculation = () => {
    setForceUpdate((prev) => !prev);
  };

  const handleResolve = (checkId: number) => {
    setPassedCheckIds((prev) => new Set(prev.add(checkId)));
    triggerIssueRecalculation();
  };

  const handleSheetValidate = (checkId: number, selectedSheet: string) => {
    context.setWorkbook((prev) => ({
      ...prev,
      selectedSheet,
    }));
    handleResolve(checkId);
  };

  const handleColumnRename = (checkId: number, column: { name: string }, newName: string) => {
    const sheet = context.workbook.workbook.Sheets[props.sheetName];
    const colLetter = column.name;
    const colIndex = XLSX.utils.decode_col(colLetter);

    if (sheet[`${colLetter}1`]) {
      sheet[`${colLetter}1`].v = newName;
    } else {
      sheet[`${colLetter}1`] = { t: 's', v: newName };
    }

    const updatedCols = context.columns.map((col) => {
      if (col.colIdx === colIndex) {
        return {
          ...col,
          colName: newName,
          data: col.data.map((cell) => (cell === undefined ? { value: null } : cell)),
        };
      }
      return col;
    });

    context.setWorkbook({ ...context.workbook });
    context.setColumns(updatedCols);
    handleResolve(checkId);
  };

  const unifiedDeleteHandler = (
    checkId: number,
    rows?: number[],
    columns?: string[]
  ) => {
    const worksheet = context.workbook.workbook.Sheets[props.sheetName];
    if (!worksheet) return;

    const rowsToDelete = rows
      ? [...new Set(rows)].map((r) => r - 1).sort((a, b) => b - a)
      : [];

    const colsToDeleteIndices = columns
      ? [...new Set(columns)].map(XLSX.utils.decode_col).sort((a, b) => b - a)
      : [];

    const aoa: unknown[][] = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      blankrows: true,
      defval: null,
    });

    let filteredAoa = rowsToDelete.length > 0
      ? aoa.filter((_, idx) => !rowsToDelete.includes(idx))
      : aoa;

    if (colsToDeleteIndices.length > 0) {
      filteredAoa = filteredAoa.map((row) =>
        row.filter((_, idx) => !colsToDeleteIndices.includes(idx))
      );
    }

    const newWs = XLSX.utils.aoa_to_sheet(filteredAoa);
    Object.keys(worksheet).forEach((k) => delete worksheet[k]);
    Object.assign(worksheet, newWs);
    worksheet['!ref'] = newWs['!ref'];

    const updatedCols = context.columns
      .filter((col) => !colsToDeleteIndices.includes(col.colIdx))
      .map((col, idx) => ({
        ...col,
        colIdx: idx,
      }));

    context.setWorkbook({ ...context.workbook });
    context.setColumns(updatedCols);
    handleResolve(checkId);
    triggerIssueRecalculation();
  };

  const handleFillNulls = (checkId: number, columnLetter: string) => {
    const worksheet = context.workbook.workbook.Sheets[props.sheetName];
    if (!worksheet) return;

    const colIndex = XLSX.utils.decode_col(columnLetter);
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    for (let row = range.s.r + 1; row <= range.e.r; row++) {
      const cellAddress = `${columnLetter}${row + 1}`;
      if (!worksheet[cellAddress] || !worksheet[cellAddress].v) {
        worksheet[cellAddress] = { t: 's', v: 'null' };
      }
    }

    const updatedCols = context.columns.map((col) => {
      if (col.colIdx === colIndex) {
        return {
          ...col,
          data: col.data.map((cell) => (cell === undefined ? { value: 'null' } : cell)),
        };
      }
      return col;
    });

    context.setWorkbook({ ...context.workbook });
    context.setColumns(updatedCols);
    handleResolve(checkId);
    triggerIssueRecalculation();
  };

  return (
    <Container>
      <Title>Validation Summary for {props.filename}</Title>
      {checks.length === 0 ? (
        <Typography>No validation issues found.</Typography>
      ) : (
        checks.map((check) => (
          <Accordion key={check.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{check.title}</Typography>
              <SummaryText>{check.summary}</SummaryText>
              <IconWrapper>
                {check.passed ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </IconWrapper>
            </AccordionSummary>

            <AccordionDetails>
              {check.availableSheets && (
                <SheetSelector>
                  <StyledSelect
                    value={check.selectedSheet}
                    size="small"
                    onChange={(e) => handleSheetValidate(check.id, e.target.value as string)}
                  >
                    {check.availableSheets.map((sheet) => (
                      <MenuItem key={sheet} value={sheet}>
                        {sheet}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  <ValidateButton
                    variant="contained"
                    onClick={() => setHasDefaultSheet(true)}
                  >
                    Validate
                  </ValidateButton>
                </SheetSelector>
              )}

              {check.zeroDataColumns && (
                <RowContainerJustified>
                  <Typography>
                    Columns with headers but no data: {check.zeroDataColumns.join(', ')}
                  </Typography>
                  <ColumnActionsContainer>
                    <FixedSizeButton
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() =>
                        unifiedDeleteHandler(check.id, undefined, check.zeroDataColumns)
                      }
                    >
                      Delete Columns
                    </FixedSizeButton>
                    <FixedSizeButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleFillNulls(check.id, check.zeroDataColumns[0])}
                    >
                      Fill with Null
                    </FixedSizeButton>
                  </ColumnActionsContainer>
                </RowContainerJustified>
              )}

              {check.emptyColumns && (
                <RowContainerJustified>
                  <Typography>
                    Completely empty columns: {check.emptyColumns.join(', ')}
                  </Typography>
                  <FixedSizeButton
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() =>
                      unifiedDeleteHandler(check.id, undefined, check.emptyColumns)
                    }
                  >
                    Delete Columns
                  </FixedSizeButton>
                </RowContainerJustified>
              )}

              {check.emptyRows && (
                <RowContainerJustified>
                  <Typography>
                    Empty rows: {check.emptyRows.join(', ')}
                  </Typography>
                  <FixedSizeButton
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => unifiedDeleteHandler(check.id, check.emptyRows)}
                  >
                    Delete Rows
                  </FixedSizeButton>
                </RowContainerJustified>
              )}

              {(check.unnamedColumns || check.duplicatedColumns) && (
                <RowContainer>
                  {(check.unnamedColumns || check.duplicatedColumns)?.map((col, idx) => (
                    <ColumnRow key={idx}>
                      <ColumnName>
                        {col.name ? `Column ${col.name}` : `Column ${col.index + 1}`}
                      </ColumnName>
                      <ActionButtons>
                        <FixedSizeTextField
                          placeholder="New Name"
                          size="small"
                          value={updatedColumns[col.name] || ''}
                          onChange={(e) =>
                            setUpdatedColumns((prev) => ({
                              ...prev,
                              [col.name]: e.target.value,
                            }))
                          }
                        />
                        <FixedSizeButton
                          variant="contained"
                          size="large"
                          onClick={() =>
                            handleColumnRename(check.id, col, updatedColumns[col.name])
                          }
                          disabled={!updatedColumns[col.name]?.trim()}
                        >
                          Rename
                        </FixedSizeButton>
                      </ActionButtons>
                    </ColumnRow>
                  ))}
                </RowContainer>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Container>
  );
};

const findIssuesInSheet = (workbook: XLSX.WorkBook, sheetName: string): SheetIssues => {
  const worksheet = workbook.Sheets[sheetName];
  if (!worksheet || !worksheet['!ref'])
    return {
      missingHeaderColumns: [],
      headerNoDataColumns: [],
      emptyRows: [],
      emptyColumns: [],
      duplicatedColumns: [],
    };

  const range = XLSX.utils.decode_range(worksheet['!ref']);
  const issues: SheetIssues = {
    missingHeaderColumns: [],
    headerNoDataColumns: [],
    emptyRows: [],
    emptyColumns: [],
    duplicatedColumns: [],
  };

  const headerMap = new Map<string, string>();

  for (let col = range.s.c; col <= range.e.c; col++) {
    const colLetter = XLSX.utils.encode_col(col);
    const headerCell = worksheet[`${colLetter}1`];
    let hasData = false;
    let isEmptyColumn = true;

    for (let row = range.s.r + 1; row <= range.e.r; row++) {
      const cell = worksheet[`${colLetter}${row + 1}`];
      if (cell?.v !== undefined) {
        hasData = true;
        isEmptyColumn = false;
      }
    }

    if (headerCell?.v) {
      const headerName = headerCell.v.toString().toLowerCase();
      if (headerMap.has(headerName)) {
        issues.duplicatedColumns.push(colLetter);
      } else {
        headerMap.set(headerName, colLetter);
      }

      if (!hasData) {
        issues.headerNoDataColumns.push(colLetter);
      }
    } else if (!isEmptyColumn) {
      issues.missingHeaderColumns.push(colLetter);
    }

    if (isEmptyColumn && !headerCell?.v) {
      issues.emptyColumns.push(colLetter);
    }
  }

  for (let row = range.s.r + 1; row <= range.e.r; row++) {
    let isEmptyRow = true;
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
      if (cell && cell.v !== undefined) isEmptyRow = false;
    }
    if (isEmptyRow) issues.emptyRows.push(row + 1);
  }

  return issues;
};

const checkWorkbookForIssues = (workbook: XLSX.WorkBook): Record<string, SheetIssues> => {
  const allIssues: Record<string, SheetIssues> = {};
  workbook.SheetNames.forEach((sheetName) => {
    allIssues[sheetName] = findIssuesInSheet(workbook, sheetName);
  });
  return allIssues;
};

const transformIssuesToChecks = (
  issues: SheetIssues,
  hasMultipleSheets: boolean,
  sheets: string[],
  passedCheckIds: Set<number>,
  hasDefaultSheet: boolean = false
): Check[] => {
  return [
    hasMultipleSheets &&
      !hasDefaultSheet && {
        id: 1,
        title: 'Multiple Sheets',
        summary: 'Multiple sheets detected',
        details: 'Please select a sheet to process',
        passed: passedCheckIds.has(1),
        availableSheets: sheets,
        selectedSheet: sheets[0],
      },

    issues.emptyRows?.length > 0 && {
      id: 2,
      title: 'Empty Rows',
      summary: `${issues.emptyRows.length} empty rows found`,
      details: `Rows: ${issues.emptyRows.join(', ')}`,
      passed: false,
      emptyRows: issues.emptyRows,
    },

    issues.emptyColumns?.length > 0 && {
      id: 3,
      title: 'Empty Columns',
      summary: `${issues.emptyColumns.length} completely empty columns`,
      details: `Columns: ${issues.emptyColumns.join(', ')}`,
      passed: false,
      emptyColumns: issues.emptyColumns,
    },

    issues.missingHeaderColumns?.length > 0 && {
      id: 4,
      title: 'Missing Headers',
      summary: `${issues.missingHeaderColumns.length} columns missing headers`,
      details: `Columns: ${issues.missingHeaderColumns.join(', ')}`,
      passed: false,
      unnamedColumns: issues.missingHeaderColumns.map((col, index) => ({
        index,
        name: col,
      })),
    },

    issues.headerNoDataColumns?.length > 0 && {
      id: 5,
      title: 'Empty Data Columns',
      summary: `${issues.headerNoDataColumns.length} columns with headers but no data`,
      details: `Columns: ${issues.headerNoDataColumns.join(', ')}`,
      passed: false,
      zeroDataColumns: issues.headerNoDataColumns,
    },

    issues.duplicatedColumns?.length > 0 && {
      id: 6,
      title: 'Duplicate Columns',
      summary: `${issues.duplicatedColumns.length} duplicate column names found`,
      details: `Columns: ${issues.duplicatedColumns.join(', ')}`,
      passed: false,
      duplicatedColumns: issues.duplicatedColumns.map((col, index) => ({
        index,
        name: col,
      })),
    },
  ].filter(Boolean) as Check[];
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5rem;
`;

const SummaryText = styled.span`
  margin-left: 15px;
  font-size: 0.9rem;
  flex: 1;
`;

const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SheetSelector = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
`;

const RowContainerPassword = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

const RowContainerJustified = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
`;

const ColumnRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
`;

const ColumnName = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 1rem;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledSelect = styled(Select)`
  width: 250px;
`;

const FixedSizeButton = styled(Button)`
  width: 131px;
  height: 40px;
`;

const ValidateButton = styled(FixedSizeButton)`
  margin-left: 10px;
`;

const FixedSizeTextField = styled(TextField)`
  width: 131px;
  height: 40px;
`;

const ColumnActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default FieldValidationFile;
