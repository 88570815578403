import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {useMutation, useQuery} from "@tanstack/react-query";
import { Row } from '../../common/flexStyles';
import TextButton from "@as_core/controls/buttons/TextButton";
import DataLoading from '@as_core/elements/DataLoading';
import { RequestT } from '../../hooks/useRequests';
import useRequestStatus from '../../hooks/useRequestStatus';
import ShowRequestStatus from "../../common/ShowRequestStatus";

const UpdateRequestStatus: {[key: string]: string} = {
  'compoundsReceived': 'Received',
  'assaysRunning': 'Running'
}

type PropsT = {
  request: RequestT;
  // eslint-disable-next-line
  handleRequestUpdate?: (id: string, updates: any) => void;
  handleCancel?: () => void;
}

const debug = false;
export const StatusUpdate = (props:PropsT) => {
  const { request, handleRequestUpdate} = props;
  const { getRequestStatus, getNextStatusStage, getStatusLabel, updateRequestStatus } = useRequestStatus();
  const [isStatusUpdated, setIsStatusUpdated] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  // load the current status
  let { isPending, isError, error, data: status } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request)
  })
  if (isError) console.error(error.message);

  const nextStage = getNextStatusStage(status);

  const updateFunction = async() => {
    setIsProcessing(true);
    return await updateRequestStatus(request, nextStage);
  }

  const mutation = useMutation({
    mutationFn: () => updateFunction(),
    onSuccess: (data) => {
      if (debug) console.log('StatusUpdate | mutation | OnSuccess | data:', data);
      status = data;
      setIsStatusUpdated(true);
      handleRequestUpdate(request.id, {'status': UpdateRequestStatus[nextStage]});
    }
  });

  const handleClick = () => {
    if (debug) console.log('StatusUpdate | handleClick');
    mutation.mutate();
  }

  if (debug) console.log('StatusUpdate | status:', status, isPending, getNextStatusStage(status));

  return (
    <Container key={'status-update-form'}>
      <Row v_align={'center'}>
        { isPending ? <DataLoading />
          :
          <ShowRequestStatus
            view={'partners'}
            request={request}
            status={status}
          />
        }
      </Row>
      <Row padding={'5px 0'}>
        <TextButton
          width={300}
          label={'Confirm ' + getStatusLabel(nextStage)}
          inProcess={isProcessing}
          isDisabled={isStatusUpdated}
          onClick={handleClick}
        />
      </Row>
    </Container>
  );
};

export default StatusUpdate;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;