import React from "react";
import { MenuItemsT } from '@as_core/menus/SideMenu';
import OrdersAddIcon from '@components/icons/orders.add';
import OrdersActiveIcon from '@components/icons/orders.active';
import FulfillmentActiveIcon from '@as_core/icons/fulfillmentActive.icon';
import FulfillmentConsultingIcon from '@as_core/icons/fulfillmentConsulting.icon';
import OrdersPastIcon from '@components/icons/orders.past';
import OurBiologyIcon from '@components/icons/ourBiology.icon';

export const ordersMenuItems: MenuItemsT[] = [
  {
    id: 'order',
    name: 'Create Screen Order',
    pathname: ['/orders/create', '/orders/confirm'],
    icon: <OrdersAddIcon />
  },
  {
    id: 'orders_active',
    name: 'My Open Orders',
    pathname: ['/orders/active', '/orders/stripe'],
    icon: <OrdersActiveIcon />
  },
  {
    id: 'orders_requests',
    name: 'Order Fulfillment',
    pathname: '/orders/requests',
    icon: <FulfillmentActiveIcon />
  },
  {
    id: 'orders_consulting',
    name: 'Consulting Fulfillment',
    pathname: '/orders/consulting',
    icon: <FulfillmentConsultingIcon />
  },
  {
    id: 'orders_complete',
    name: 'My Completed Orders',
    pathname: '/orders/complete',
    icon: <OrdersPastIcon />
  },
  {
    id: 'biology',
    name: 'Our Biology',
    pathname: '/biology/systemetric/summary',
    icon: <OurBiologyIcon />
  }
];

const debug = false;
export const getOrdersMenus = (subscription: string) => {
  if (debug) console.log('getMenuAccount | subscription', subscription);
  return ordersMenuItems;
}


