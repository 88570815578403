import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import useRepos from '../useRepos';
import { UserContext } from '@stores/UserContext';
import DataLoading from "@as_core/elements/DataLoading";
import { Label } from "../../shared/flexStyles";
import TextButton from "@as_core/controls/buttons/TextButton";
import TrashIcon from "@components/icons/trash.icon";
import Alert from '@components/elements/Alert';

type PropsT = {
  repoId: string;
  onDelete?: (repoId: string, changeType: string) => void;
}

const debug = false;
const RepoDelete = (props: PropsT) => {
  const { user, setUser } = useContext(UserContext);
  const { repositories } = user.appInfo;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState<boolean>(false);
  const { deleteRepository } = useRepos();
  if (debug) console.log('RepoCreate | user', user);

  const handleDeleteConfirm = () => {
    if (debug) console.log('RepoDelete | handleDeleteConfirm ');
    handleDeleteRepository();
  };

  const handleDeleteRepository = () => {
    if (debug) console.log('UPDATE | isUpdating', isUpdating, 'user repoId', repositories.current);
    setIsUpdating(true);
    deleteRepository(props.repoId).then(() => {
      setIsUpdating(false);
      if (props?.onDelete) props.onDelete(props.repoId, 'repoDeleted');
      let updatedRepositories = repositories;
      updatedRepositories.owned = updatedRepositories.owned.filter((r) => r !== props.repoId);
      if (debug) console.log('updatedRepositories:', updatedRepositories);
      setUser((prev) => ({ ...prev, appInfo: { ...prev.appInfo, repositories: updatedRepositories } }));
    });
  };
  if (debug) console.log('MAIN | isUpdating', isUpdating, 'user repoId', repositories.current);

  return (
    <DeleteContainer>
      {isUpdating ?
        <DataLoading />
        :
        <Action width={500}>
          <TextButton
            key={'action_create'}
            label={'Delete Repository'}
            icon={<TrashIcon />}
            width={200}
            height={30}
            onClick={() => setIsDeleteAlertOpen(true)}
          />
          <Alert
            type={'deleteConfirm'}
            title={'Delete Repository'}
            message={'Confirm delete by typing DELETE below to Enable Delete'}
            alertOpen={isDeleteAlertOpen}
            onConfirm={handleDeleteConfirm}
            closeAlert={() => setIsDeleteAlertOpen(false)}
          />
        </Action>
      }
    </DeleteContainer>
  );
};

export default RepoDelete;

const DeleteContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

const Action = styled(Label)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
`;


