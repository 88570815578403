import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import useRepos from '../useRepos';
import { UserContext } from '@stores/UserContext';
import DataLoading from "@as_core/elements/DataLoading";
import { Container, Row, Label } from "../../shared/flexStyles";
import TextButton from "@as_core/controls/buttons/TextButton";
import DataFolderNewIcon from "@as_core/icons/dataFolderNew.icon";
import { BasicTextInput } from "@as_core/account/forms/FormStyles";
import Alert from "@components/elements/Alert";

type PropsT = {
  onChange?: (repoId: string, changeType: string) => void;
}

const debug = false;
const RepoCreate = (props: PropsT) => {
  const { user, setUser } = useContext(UserContext);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [newRepositoryName, setNewRepositoryName] = useState<string>('');
  const { createRepository } = useRepos();
  if (debug) console.log('RepoCreate | user', user);

  const handleNewRepository = () => {
    if (debug) console.log('UPDATE | isUpdating', isUpdating, 'user repoId', user.appInfo.repositories.current.uuid);
    setIsCreateOpen(false);
    setIsUpdating(true);
    createRepository(newRepositoryName).then((newRepo) => {
      setIsUpdating(false);
      if (newRepo !== null) {
        const updatedRepositories = user.appInfo.repositories;
        updatedRepositories.owned = [...updatedRepositories.owned, newRepo.uuid]
        if (debug) console.log('updatedRepositories:', updatedRepositories);
        setUser((prev) => ({ ...prev, appInfo: { ...prev.appInfo, repositories: updatedRepositories } }));
        if (props?.onChange) props.onChange(newRepo.uuid, 'repoCreated');
      } else {
        setIsUpdating(false);
      }
    });
  };
  if (debug) console.log('MAIN | isUpdating', isUpdating, 'user repoId', user.appInfo.repositories.current.uuid);

  return (
    <Container hasBorder={false}>
      <Row>
        {isUpdating ?
          <DataLoading />
          :
          <Action width={500}>
            {isCreateOpen ?
              <>
                <BasicTextInput
                  value={newRepositoryName}
                  placeholder={'Enter a repository name ...'}
                  width={'350px'}
                  onChange={(v) => setNewRepositoryName(v.target.value)}
                />
                <TextButton
                  key={'action_create'}
                  label={'Create'}
                  width={120}
                  height={30}
                  isDisabled={newRepositoryName.length < 5}
                  onClick={handleNewRepository}
                />
                <TextButton
                  key={'action_cancel'}
                  label={'Cancel'}
                  width={120}
                  height={30}
                  onClick={() => setIsCreateOpen(false)}
                />
              </>
              :
              <TextButton
                key={'create_new_repository'}
                label={'Create a New Repository'}
                icon={<DataFolderNewIcon />}
                width={220}
                onClick={() => setIsCreateOpen(true)}
                isMuted={true}
              />
            }
          </Action>
        }
      </Row>
      <Alert
        type={'general'}
        title={'Create Repository'}
        message={'Error trying to create repository. Please submit a bug report with details'}
        alertOpen={alertOpen}
        closeAlert={() => setAlertOpen(false)}
      />
    </Container>
  );
};

export default RepoCreate;

const Action = styled(Label)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 30px;
`;


