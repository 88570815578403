import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

interface PropsT {
  size?: number;
}


const CompoundQCIcon = ({
    size = 24,
  }: PropsT) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fill={colorHighlight} d="M13.3,13.9l-5.5,5.4c-.7.7-.7,1.8,0,2.4l.5.5c.7.7,1.8.7,2.4,0l8.4-8.3h-5.9Z" />
          <path stroke={color} strokeMiterlimit={10}
                d="M22.4,10.4l-1.1-1.1-.5-.5-1.1-1.1c-.5-.5-1.3-.2-1.3.5v.6s-10.6,10.4-10.6,10.4c-.7.7-.7,1.8,0,2.4l.5.5c.7.7,1.8.7,2.4,0l10.6-10.4h.6c.7,0,1-.9.5-1.4Z" />
          <path fill={color}
          d="M6.1,9.9c0-2.6,3-3,3-4.7s-.6-1.1-1.4-1.1-1.8.8-1.8.8l-1.8-2.2s1.4-1.6,4-1.6,4.5,1.3,4.5,3.8-3.3,3.6-3.3,5.3v.7h-3.2v-1ZM6.2,12.3h3v2.9h-3v-2.9Z" />
        </g>
      </svg>
    </>)
}

export default CompoundQCIcon;