import React, {useState} from 'react';
import styled from 'styled-components/macro';
import { useQuery } from '@tanstack/react-query';
import DataLoading from '@as_core/elements/DataLoading';
import MenuButton from "@as_core/elements/MenuButton";
import DraggableModal from "@as_core/elements/DraggableModal";
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import {getFieldValue} from '../utils/renderValues';
import { Row, Title } from './flexStyles';
import useVendors from '../hooks/useVendors';
import { RequestT, RequestUpdateT } from '../hooks/useRequests';
import {UserEmailDictionaryT} from '../hooks/useUserEmails';
import UpdateRequest, {getRequestUpdateModalTitle, UpdateModalDimensions} from "../forms/UpdateRequest";
import BackArrowIcon from '@components/icons/backArrow.icon';
import RequestUserDetails from '../user/RequestDetails';
import RequestPartnerDetails from '../partners/RequestDetails';
import useRequestStatus from '../hooks/useRequestStatus';



const titleField: BasicTableFieldsT = { value: 'name', label: 'Name', type: 'string' }

interface PropsI {
  action: string;
  viewMode: string;
  request: RequestT;
  userEmails: UserEmailDictionaryT;
  handleRequestUpdate: (id: string, updates: RequestUpdateT) => Promise<void>;
  handleBack?: () => void;
}

const debug = false;
const RequestDetails = (props: PropsI) => {
  const {action, viewMode, request, userEmails, handleRequestUpdate} = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { getRequestStatus } = useRequestStatus();
  const { getRequestType } = useVendors();

  if (debug) console.log('requestDetails | {viewMode, request.id, requestType}:',
                            viewMode, request.id, getRequestType(request));

  // load the current status
  let { isPending, isError, error, data: status } = useQuery({
    queryKey: ['requestStatus', request.id],
    queryFn: () => getRequestStatus(request),
  })
  if (isError) console.error(error.message);

  const handleModalClose = () => {
    setIsModalOpen(false);
  }

  const handleClick = () => {
    debug && console.log('common/RequestsDetails | handleClick ', request.id);
    setIsModalOpen(true);
  };

  if (debug) console.log('common/requestDetails | status ', status);

  return (
    <RequestDetailsContainer>
      <Row h_align={'center'} width={'100%'}>
        { props?.handleBack && (
          <MenuButton
            onClick={props.handleBack}
            width={50}
            icon={<BackArrowIcon />}
            text={'Back to List'}
            tooltipPlacement={'bottom'}
          />
        ) }
        <Title>{getFieldValue(request, titleField)}</Title>
        <Title></Title>
      </Row>
      <Row v_align={'flex-start'}>
        { isPending ?
            <DataLoading />
        : viewMode === 'user' ?
          <RequestUserDetails
            request={request}
            status={status}
            handleRequestUpdate={handleRequestUpdate}
          />
        :
          <RequestPartnerDetails
            request={request}
            userEmails={userEmails}
            handleRequestUpdate={handleRequestUpdate}
            handleEdit={handleClick}
          />
        }
      </Row>
      <DraggableModal
        title={getRequestUpdateModalTitle(action, viewMode)}
        height={
          Object.hasOwn(UpdateModalDimensions, action)
            ? UpdateModalDimensions[action].height
            : 600
        }
        width={
          Object.hasOwn(UpdateModalDimensions, action)
            ? UpdateModalDimensions[action].width
            : 600
        }
        isModalOpen={isModalOpen}
        onCloseClick={handleModalClose}
      >
        <UpdateRequest
          action={action}
          viewMode={viewMode}
          request={request}
          handleRequestUpdate={handleRequestUpdate}
          closeDialog={handleModalClose}
        />
      </DraggableModal>

    </RequestDetailsContainer>
  );
};

export default RequestDetails;

const RequestDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: max-content;
  width: max-content;
`;
