import React from "react";
import styled from 'styled-components/macro';
import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';
import {OrderT} from "./types";
import {renderOrderDetails} from "@business/orders/components/utils";
import {DisplayField, Label, Value} from '../common/flexStyles';
import { OrderFields } from '../utils/tableDefinitions';
import { getFieldValue } from '../utils/renderValues';

interface PropsI {
  order: OrderT;
  orderMode: string;
}

const debug = false;
const OrderDetailTile = (props: PropsI) => {
  const {order, orderMode} = props;
  if (debug) console.log('ViewOrderDetails | orderMode:', orderMode, order);

  if (!Object.keys(order)) {
    return(
    <OrderDetailsContainer>Error: Order empty</OrderDetailsContainer>
    )
  }

  if (!Object.hasOwn(OrderFields, orderMode)) {
    return(
    <OrderDetailsContainer>Error: Unknown orderMode: {orderMode}</OrderDetailsContainer>
    )
  }

  const displayValue = (order: OrderT, item: BasicTableFieldsT, index: number) => {
    if (item?.type === 'line') {
      return <HorizontalLine key={index} />;
    }
    return (
      <DisplayField key={`order_details_${index}`}>
        <Label width={'150px'}>{item.label}:</Label>
        <Value width={'200px'}>{getFieldValue(order, item)}</Value>
      </DisplayField>
    );
  };

  return (
    <OrderDetailsContainer>
      <ReportOrderContainer width={'375px'}>
        { OrderFields[orderMode].map((item, index) =>
            displayValue(order, item, index))}
      </ReportOrderContainer>
      {renderOrderDetails(order, 'orderDetails')}
    </OrderDetailsContainer>
  );
};

export default OrderDetailTile;

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: max-content;
  width: max-content;
  padding: 10px;
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundQuaternary};
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const ReportOrderContainer = styled.div<{width:string}>`
  display: flex;
  flex-direction: column;
  width: ${(p)=>p.width}px;
  height: max-content;
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
`;
