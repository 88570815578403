import React from "react";
import { AxiosRequestConfig } from 'axios';
import { ProductsCategoriesFull } from "./products.local";
import {ProductCategoryT} from "./products.types";
import useCognito from "@as_core/account/useCognito";
import {authCoreClient} from "@utils/api/base";
import CellHealthIcon from '@components/icons/cellhealth.icon';
import ZebrafishIcon from '@components/icons/zebrafish.icon';
import BiomimeticsIcon from '@components/icons/biomimetics.icon';
import CompoundQCIcon from '@components/icons/compoundQC.icon';
import { processAxiosResponse } from '@utils/api/utils';

export const showCategoryCodes: {[key:string]: string[]} = {
  'screen': ['AS-SYS', 'AS-ZBE', 'AS-BMVK', 'AS-CQC-SST']
};

const categoryIcons = {
  'AS-SYS': <CellHealthIcon size={48}/>,
  'AS-ZBE': <ZebrafishIcon size={48}/>,
  'AS-BMVK': <BiomimeticsIcon size={48}/>,
  'AS-CQC-SST': <CompoundQCIcon size={48}/>
}

export function getIcon(code: string) {
  return Object.hasOwn(categoryIcons, code) ? categoryIcons[code] : null;
}

export type StripePriceT = {
  id: string,
  object: string,
  active: boolean,
  billing_scheme: string,
  created: Date,
  currency: string,
  custom_unit_amount: string | number,
  livemode: boolean,
  lookup_key: string,
  metadata: {[key: string]: string},
  nickname: string,
  product: string,
  recurring: boolean,
  tax_behavior: string,
  tiers_mode: string | number,
  transform_quantity: number,
  type: string,
  unit_amount: number,
  unit_amount_decimal: string
}

export type StripeProductT = {
  id: string,
  object: "product",
  active: boolean,
  attributes: string[],
  created: number,
  default_price: string,
  description: string,
  features: string,
  images: string[],
  livemode: boolean,
  metadata: {
    catalogNumber?: string,
    catalogType?: string,
    categoryCode?: string,
    pricingQuantity?: string,
    pricingType?: string,
    system?: string,
    unitOrderMinSize?: string,
    unitSize?: string
  },
  prices?: {[key: string]: StripePriceT},
  price?: StripePriceT,
  name: string,
  package_dimensions: string,
  shippable: boolean,
  statement_descriptor: string,
  tax_code: string,
  type: string,
  unit_label: string,
  "updated": Date,
  "url": string
}

export const Products = {
  all: (token:string, config: AxiosRequestConfig) => authCoreClient(token).get('/products/with_prices', config),
};

const useStripeProducts = () => {
  const { getToken } = useCognito();

  const getAllProducts = async (region: string): Promise<Array<StripeProductT>> => {
    const params = {limit: 100, region: region === null || region === '' ? 'OUS' : region};
    const config: AxiosRequestConfig = {params};
    const response= await Products.all(getToken(), config);
    const products = processAxiosResponse('getAllProducts', response);
    return products.sort((a:StripeProductT, b:StripeProductT) =>
      a.metadata?.catalogNumber && a.metadata.catalogNumber.localeCompare(b.metadata?.catalogNumber));
  };

  const getProductCategories = async (categoryType: string): Promise<Array<ProductCategoryT>> => {
    if (!Object.hasOwn(showCategoryCodes, categoryType)) {
      console.error('getProductCategories - No category type found of ' + categoryType);
      return [];
    }
    return ProductsCategoriesFull
    .filter((c) => showCategoryCodes[categoryType].includes(c.categoryCode))
    .map((c) => ({ ...c, icon: getIcon(c.categoryCode)}));
  };

  return {
    getProductCategories,
    getAllProducts,
  };
};

export default useStripeProducts;
