import React from 'react';
import styled from 'styled-components/macro';
import { RequestT } from '../hooks/useRequests';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import DownloadFile from '@as_core/controls/outputs/DownloadFile';

const PlateMapInfo:string = 'The plate map template includes the shipping instructions and '
+ 'the template spreadsheet to add the location information for each of the compounds sent in the plate. '
+ 'This template is specific to the types of assay/screens and the laboratory performing the screen, so '
+ 'Please confirm you have the correct template downloaded, and correctly filled out.'

interface PropsI {
  request: RequestT;
}

const PlateMapTemplate = (props:PropsI) => {
  const { request } = props;
  return (
  <PlateMapTemplateContainer>
    <HoverInfoText text={PlateMapInfo}/> &nbsp;
    Plate Map Template File:
    <DownloadFile file={request.assigned_to + '.xlsx'} path={'/documents/templates/'}/>
  </PlateMapTemplateContainer>
  )
}
export default PlateMapTemplate;

const PlateMapTemplateContainer = styled.div`
  display: flex;
  font-size: 12px;
  border-radius: 15px;
  padding: 2px;
  margin-bottom: 5px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary};
  background: ${(p) => p.theme.palette.backgroundQuaternary};
`;