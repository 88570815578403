import { AxiosResponse } from 'axios';
import { ResponseT } from '@src/type';

const debug = false;
export function processAxiosResponse<T> (method: string, response: AxiosResponse<T>)  {
  if (response.status !== 200) {
    console.error(`API error in ${method}: `, response.statusText);
    return null;
  }
  if (debug) console.log('processAxiosResponse | {method, ResponseData}:', method, response.data);
  return response.data;
}

export function processAxiosResponseElement<T> (method: string, response: AxiosResponse<ResponseT<T>>)  {
  const responseData = processAxiosResponse(method, response);
  if (debug) console.log('processAxiosResponseElement | {method, ResponseData}:', method, responseData);
  return responseData.data;
}

export function processAxiosResponseFirstElementArray<T> (method: string, response: AxiosResponse<ResponseT<T>>)  {
  const responseData = processAxiosResponse(method, response);
  if (debug) console.log('processAxiosResponseElementArray | {method, ResponseData}:', method, responseData);
  return responseData.data[0];
}