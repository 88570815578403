import React from 'react';
import UploadComponent from '@components/UploadComponent';
import AbbvieUpload from '@components/abbvieUpload';
import useFileImport from './useFileImport';
import styles from '../../../components-style/UploadComponents.module.css';
import styled from 'styled-components/macro';
import { FlexRow, FlexItem, FlexColumn } from '@components/layout/FlexStyles';
import { TextSmall, TextSmallBold } from '@components/elements/TextStyles';
import { UploadOptionT } from '@src/type';


interface UploadFileOptionsT {
  choices: UploadOptionT[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const UploadFile = ({ choices, onChange }: UploadFileOptionsT) => {
  const { getWorkbook, context, loadWorkbookAndColumns } = useFileImport();
  const handleOnDrop = async (acceptedFiles) => {
    const workbook = await getWorkbook(acceptedFiles);
    loadWorkbookAndColumns(workbook, 0, 0, context.importer !== 'compound_set');
  };
  return (
    <UploadFileContainer>
      <FlexColumn h_centered v_centered width={'100%'} style={{border: '1px solid rgb(214, 11, 81)', borderRadius: '15px', padding: '5px'}}>
        <TextSmall>
          We support Excel (.xlsx) file formats. Additional file formats are coming soon.
        </TextSmall>
        <TextSmallBold color="primary">
          To ensure a seamless experience, please label each column clearly.
        </TextSmallBold>
        <TextSmallBold color="accent">
          Follow instructions as listed in each of the steps.
        </TextSmallBold>
      </FlexColumn>
      <FlexRowStyle>
        <FlexItem>
          <div className={styles.componentUploadFile}>
            {context.importer === 'abbvie' ? (
              <AbbvieUpload />
            ) : (
              <UploadComponent onDrop={handleOnDrop} />
            )}
          </div>
        </FlexItem>
      </FlexRowStyle>
    </UploadFileContainer>
  );
};
export default UploadFile;

const UploadFileContainer = styled.div`
  height: 100%;
  // margin-top: 50px;
  min-height: 275px;
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FlexRowStyle = styled(FlexRow)`
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;