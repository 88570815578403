import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import useRepos, {RepositoryDetailsT} from '../useRepos';
import DataLoading from "@as_core/elements/DataLoading";
import TextButton from "@as_core/controls/buttons/TextButton";
import Title from "@ui-components/elements/Title";
import MenuButton from "@components/controls/MenuButton";
import EditIcon from "@components/icons/edit.icon";
import {BasicTextInput} from "@as_core/account/forms/FormStyles";

type PropsT = {
  repo: RepositoryDetailsT;
  allowEdit: boolean;
  pushRefresh?: (repoId:string, changeType:string)=>void;
}

const debug = false;
const RepoNameEdit = (props: PropsT) => {
  const { repo } = props;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [repoName, setRepoName] = useState<string>(repo.name);
  const { updateRepository } = useRepos();
  if (debug) console.log('RepoActiveState | props', props);

  const handleUpdateName = () => {
    if (debug) console.log('UPDATE | isUpdating:', isUpdating, 'repoName:', repo.name, 'repoId:', repo.uuid);
    setIsUpdating(true);
    updateRepository(props.repo.uuid, {name: repoName}).then(()=> {
      setIsUpdating(false);
      setIsEditingName(false);
      if (props?.pushRefresh) props.pushRefresh(props.repo.uuid, 'nameUpdated');
    });
  };

  useEffect(() => {
    setRepoName(props.repo.name);
    setIsEditingName(false);
  }, [repo])

  return (
    <Container>
      { isUpdating ?
        <DataLoading />
        :
        <>
          { isEditingName ?
            <EditContainer>
              <BasicTextInput
                value={repoName}
                width={'300px'}
                onChange={(v)=>setRepoName(v.target.value)}
              />
              <TextButton
                key={`action_update_${repo.uuid}`}
                label={'Update'}
                width={80}
                height={30}
                isDisabled={repoName.length < 5 || repoName === repo.name}
                onClick={handleUpdateName}
              />
              <TextButton
                key={'action_cancel'}
                label={'Cancel'}
                width={80}
                height={30}
                onClick={()=> setIsEditingName(false)}
              />
            </EditContainer>
          :
            <Title text={repo.name}/>
          }
          {props.allowEdit && !isEditingName ?
            <MenuButton
              text={'Edit Repo Name'}
              icon={<EditIcon />}
              margin={'1px'}
              tooltipPlacement='top-start'
              onClick={() => setIsEditingName(prev => !prev)}
            />
            :
            null
          }
        </>
      }
    </Container>
  );
};

export default RepoNameEdit;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
`;

const EditContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin: 5px;
  border-radius: 15px;
  background-color: ${(p) => p.theme.palette.backgroundPrimary};

`;


