import React from 'react';
import styled from 'styled-components/macro';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface ICheckBox {
  checked: boolean;
  onClick?: (v) => void;
}

const CheckBoxComponent = (props: ICheckBox) => {
  const { checked } = props;

  return (
    <CheckBoxContainer
      onClick={props?.onClick ? () => props.onClick(!checked) : () => {}}
    >
      {checked ? <StyledChecked /> : <StyledUnChecked />}
    </CheckBoxContainer>
  );
};

export default CheckBoxComponent;

const CheckBoxContainer = styled.div`
  display: flex;
  width: 24px;
  cursor: pointer;
  color: ${(p) => p.theme.palette.accentPrimary};
  &:hover {
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;

const StyledChecked = styled(CheckBox)``;

const StyledUnChecked = styled(CheckBoxOutlineBlank)``;
