import React, {ReactNode} from 'react';
import styled from 'styled-components/macro';

type PropsI = {
  cards: ReactNode[];
  onSelect?: (item:string, quantity: number) => void;
};

const debug = false;
const ProductsCarousel = (props:PropsI) => {
  if (debug) console.log('CardCarousel | cards:');

  if (!props.cards || props.cards.length === 0) {
    return(<>Error: No Cards Available</>)
  }

  return (
    <CardCarouselContainer>
      <CardContainerScroll>
        { props.cards.map((item, index) => {

          return(
            <CardContainer key={`card_container_${index}`}>
              { item }
            </CardContainer>
          )})}
      </CardContainerScroll>
    </CardCarouselContainer>
  );
};

export default ProductsCarousel;

const CardCarouselContainer = styled.div`
  display: flex;
  position: relative;
  max-width: calc(100vw - 70px);
  flex-direction: row;
  overflow-x: auto;
`;

const CardContainerScroll = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  padding: 5px;
`

