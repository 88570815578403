import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "@src/store";
import MatchedColumnRow from '../../shared/assignColumns/MatchedColumnRow';
import UnMatchedColumnRow from '../../shared/assignColumns/UnMatchedColumnRow';
import useFileImport from '../../useFileImport';
import levenshtein from 'js-levenshtein';
import { setIsGlobalChecked } from '@stores/FileImportContext';

const findBestMatch = (input: string, fields: object) => {
  let bestMatch = { field: null, score: Infinity };
  Object.values(fields).forEach(field => {
    ['field_id', 'symbol', 'short_name', 'long_name'].forEach(key => {
      if (field[key]) {
        const score = levenshtein(input, field[key]);
        if (score < bestMatch.score) {
          bestMatch = { field: field["short_name"], score };
        }
      }
    });
  });
  return bestMatch.score <= 0.3 * Math.max(input.length, bestMatch.field.length) ? bestMatch.field : 'no automatic match found';
};

interface RowBuilderT {
  fieldFilter?: string;
  onSubmit: (idx: number) => void;
  onDeselect: (idx: number) => void;
  onColumnPicked: (column: { columnIdx: number, field_id: string }) => void;
  startMatching: boolean;
  setStartMatching: (value: boolean) => void;
  mapped_FieldName: string;
}

const RowBuilder = (props: RowBuilderT) => {
  const { fieldFilter = 'all', onSubmit, onDeselect, onColumnPicked, startMatching, setStartMatching } = props;
  const { context: { columns, setColumns } } = useFileImport();
  const { fields: fieldDescriptions } = useSelector((state: RootState) => state.fields);
  const childChecked = useSelector((state: RootState) => state.globalCheckbox.childChecked);
  const dispatch = useDispatch();

  const handleMatchClick = (columnIdx, automaticMatch) => {
    const matchedField = Object.keys(fieldDescriptions).find(
      key =>
        fieldDescriptions[key].field_id === automaticMatch ||
        fieldDescriptions[key].symbol === automaticMatch ||
        fieldDescriptions[key].short_name === automaticMatch ||
        fieldDescriptions[key].long_name === automaticMatch
    );

    if (matchedField) {
      onColumnPicked({ columnIdx, field_id: automaticMatch });
      // Update the column state with the new matched field
      setColumns(columns.map((col, idx) => {
        if (idx === columnIdx) {
          return { ...col, field_id: matchedField, fieldFilter };
        }
        return col;
      }));
      dispatch(setIsGlobalChecked(false));
    }
  };

  useEffect(() => {
    if (startMatching) {
      const updatedColumns = columns.map((column, columnIdx) => {
        const automaticMatch = findBestMatch(column.colName, fieldDescriptions);
        const matchedField = Object.keys(fieldDescriptions).find(
          key =>
            fieldDescriptions[key].field_id === automaticMatch ||
            fieldDescriptions[key].symbol === automaticMatch ||
            fieldDescriptions[key].short_name === automaticMatch ||
            fieldDescriptions[key].long_name === automaticMatch
        );
        if (matchedField) {
          const newFieldFilter = column.fieldFilter === 'Identity' ? 'Identity' : 'all';
          onColumnPicked({ columnIdx, field_id: automaticMatch });
          return { ...column, field_id: matchedField, newFieldFilter };
        }
        return column;
      });
      setColumns(updatedColumns);
      dispatch(setIsGlobalChecked(false));
      setStartMatching(false);
    }
  }, [startMatching]);


  return (
    <>
      {columns.map((column, columnIdx) => {
        const fieldName: string = column.field_id !== null
          ? (_.get(fieldDescriptions, column.field_id + '.short_name', column.field_id) as string)
          : 'no match';
        const automaticMatch = findBestMatch(column.colName, fieldDescriptions);
        if (column.field_id !== null) {
          return (
            <MatchedColumnRow
              key={columnIdx}
              columnIdx={columnIdx}
              fieldName={fieldName}
              automaticMatch={automaticMatch}
              onClick={onDeselect}
            />
          );
        } else {
          return (
            <UnMatchedColumnRow
              key={columnIdx}
              columnIdx={columnIdx}
              automaticMatch={automaticMatch}
              onSubmit={onSubmit}
              onMatchClick={handleMatchClick}
            />
          );
        }
      })}
    </>
  );
};

export default RowBuilder;
