import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@stores/UserContext';

import { userFormFields } from './forms/config';
import { renderAccountField } from './utils';
import MenuButton from '@components/controls/MenuButton';
import EditIcon from '@components/icons/edit.icon';
import UploadIcon from '@components/icons/upload.icon';
import CancelIcon from '@components/icons/cancel.icon';
import { GridContainer, GridItem } from '@components/layout/GridStyles';
import InputField from '@as_core/components/inputs/InputField';
import { get, set, cloneDeep } from 'lodash';
import useUserAppInfo from '@src/utils/useUserAppInfo';
import InputCountry from './fields/InputCountry';

const AccountDetails = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  const [localUserInfo, setLocalUserInfo] = useState(cloneDeep(user?.regInfo));
  const { updateUserInfo } = useUserAppInfo();

  const handleSave = () => {
    updateUserInfo({
      address: localUserInfo.address,
      name: localUserInfo.name,
      organization: localUserInfo.organization,
    }).then((response) => {
      setUser((previous) => ({
        ...previous,
        regInfo: { ...response[0] },
      }));
      setIsEditing(false);
    });
  };

  const editButtons = useMemo(() => {
    if (isEditing) {
      return (
        <>
          <MenuButton
            text={'Save Changes'}
            icon={<UploadIcon />}
            margin={'1px'}
            tooltipPlacement='bottom-end'
            onClick={handleSave}
          />
          <MenuButton
            text={'Cancel Changes'}
            icon={<CancelIcon />}
            margin={'1px'}
            tooltipPlacement='bottom-end'
            onClick={() => setIsEditing(false)}
          />
        </>
      );
    } else {
      return (
        <MenuButton
          text={'Edit My Information'}
          icon={<EditIcon />}
          margin={'1px'}
          tooltipPlacement='bottom-end'
          onClick={() => setIsEditing(true)}
        />
      );
    }
  }, [isEditing]);

  const onUpdateUser = (value: string, key: string) => {
    const info = { ...localUserInfo };
    set(info, key, value);
    setLocalUserInfo(info);
  };

  return (
    <UserEditContainer>
      <UserAccountInformation>
        <HeaderRow>
          <Title>User Account Details:</Title>
          <EditButtons>{editButtons}</EditButtons>
        </HeaderRow>
        {userFormFields.map((row, index) => {
          if (isEditing) {
            return (
              <GridContainer key={index}>
                {row.map((item) => {
                  if (item.editable) {
                    if (item.type === 'country') {
                      return (
                        <GridItem key={item.value}>
                          <InputCountry
                            id={item.value}
                            label='Country'
                            value={get(localUserInfo, item.value, '')}
                            onChange={(value) =>
                              onUpdateUser(value, item.value)
                            }
                            handleBlur={() => {}}
                          />
                        </GridItem>
                      );
                    } else {
                      return (
                        <GridItem key={item.value}>
                          <InputField
                            value={get(localUserInfo, item.value, '')}
                            onChange={(event) =>
                              onUpdateUser(event?.target?.value, item.value)
                            }
                            name={item.value}
                            label={item.label}
                            size='small'
                          />
                        </GridItem>
                      );
                    }
                  }
                })}
              </GridContainer>
            );
          } else {
            return (
              <GridContainer key={'row_' + index}>
                {row.map((f) => renderAccountField(f, index, user.regInfo))}
              </GridContainer>
            );
          }
        })}
      </UserAccountInformation>
    </UserEditContainer>
  );
};
export default AccountDetails;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const UserAccountInformation = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 300px;
  height: max-content;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${(p) => p.theme.palette.accentSecondary};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const EditButtons = styled.div`
  display: flex;
  width: 100px;
  margin-left: 10px;
`;
