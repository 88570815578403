import React from 'react';
import styled from 'styled-components/macro';
import Spinner from './Spinner';

interface AccountButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  inProcess?: boolean;
  type?: string;
}

const AccountButton = (props: AccountButtonProps) => {
  return(
    <StyledButton
      key={props.label}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      { props?.inProcess ? <Spinner /> : props.label }
    </StyledButton>

  )
}
export default AccountButton;

const StyledButton = styled.div<{disabled: boolean}>`
  width: 200px;
  height: 28px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.disabled ? 
          p.theme.palette.textSecondary : p.theme.palette.accentPrimary};
  border: 1px solid ${(p) => p.disabled ? 
          p.theme.palette.textPrimary : p.theme.palette.textSecondary};
  border-radius: 10px;
  cursor: ${(p) => p.disabled ? 'default': 'pointer'};
  &:hover {
    background-color: ${(p) => p.disabled ?
            p.theme.palette.textSecondary : p.theme.palette.accentSecondary};
  }
`;
StyledButton.defaultProps = {
  disabled: false
}