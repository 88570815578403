import { BasicTableFieldsT } from '@as_core/tables/BasicTable/BasicTable';

export const OrderFields: {[viewMode: string]: BasicTableFieldsT[]} = {
  'success': [
    { value: 'created',         label: 'Created',              type: 'date' },
    { value: 'status',          label: 'Status',               type: 'string' },
    { value: 'type',            label: 'Type',                 type: 'string' },
    { value: 'payment.amount',  label: 'Total Cost',           type: 'cost' },
    { value: 'payment_type',    label: 'Payment Type',         type: 'string' },
    { value: 'payment.created', label: 'Payment Date',         type: 'date' },
  ],
  'canceled': [
    { value: 'created',   label: 'Created',       type: 'date', },
    { value: 'status',    label: 'Status',        type: 'string' },
    { value: 'type',      label: 'Type',          type: 'string' },
    { value: 'requests',  label: '# Line Items',  type: 'array_count' }
  ]
}

export const ColDef: {[key:string]: BasicTableFieldsT[]} = {
  'open': [
    { value: 'view', label: '', type: 'view', width: 40 },
    { value: 'edit', label: '', type: 'edit', width: 40 },
    { value: 'created_at', label: 'Created', type: 'date', width: 70 },
    { value: 'user_email', label: 'Customer', type: 'email', width: 225 },
    { value: 'prod_name', label: 'Request', type: 'prod_name', width: 180 },
    { value: 'quantity', label: 'Quantity', type: 'quantity', width: 100 },
    { value: 'assigned_to', label: 'Assigned To', type: 'vendor', width: 150 },
    { value: 'status', label: 'Status', type: 'status', width: 120 },
    { value: 'name', label: 'Name', type: 'hover_info', width: 70},
    { value: 'updated_at', label: 'Updated', type: 'date', width: 70 },
  ],
  'unassigned': [
    { value: 'view', label: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', type: 'computed', width: 100 },
    { value: 'title', label: 'Title', type: 'string', width: 200 },
    { value: 'category', label: 'Category', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', type: 'string', width: 80 },
    { value: 'submit.submitter.email', label: 'Submitter', type: 'string', width: 200 },
    { value: 'submit.time_submitted', label: 'Sub\'d', type: 'date', width: 80 },
    { value: 'submit.time_submitted', label: 'Ack\'d', type: 'date', width: 80 },
  ],
  'active': [
    { value: 'view', label: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', type: 'computed', width: 55 },
    { value: 'title', label: 'Title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', type: 'string', width: 75 },
    { value: 'resolution.assigned', label: 'Assigned To', type: 'string', width: 240 },
    { value: 'submit.submitter.email', label: 'Submitter', type: 'string', width: 200 },
    { value: 'submit.time_submitted', label: 'Sub\'d', type: 'date', width: 80 },
    { value: 'submit.time_acknowledged', label: 'Ack\'d', type: 'date', width: 80 },
    { value: 'resolution.time_assigned', label: 'Assigned', type: 'date', width: 80 },
  ],
  'processing': [
    { value: 'view', label: '', type: 'view', width: 40 },
    { value: 'created_at', label: 'Created', type: 'date', width: 70 },
    { value: 'user_email', label: 'Customer', type: 'email', width: 225 },
    { value: 'prod_name', label: 'Request', type: 'prod_name', width: 180 },
    { value: 'quantity', label: 'Quantity', type: 'quantity', width: 100 },
    { value: 'assigned_to', label: 'Assigned To', type: 'vendor', width: 150 },
    { value: 'status', label: 'Status', type: 'status', width: 120 },
    { value: 'name', label: 'Name', type: 'hover_info', width: 70},
    { value: 'updated_at', label: 'Updated', type: 'date', width: 70 },
  ],
  'closed': [
    { value: 'view', label: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', type: 'computed', width: 55 },
    { value: 'title', label: 'Title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', type: 'string', width: 75 },
    { value: 'resolution.assigned', label: 'Assigned To', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', type: 'string', width: 200 },
    { value: 'submit.submitter.email', label: 'Sub\'d', type: 'date', width: 80 },
    { value: 'resolution.time_resolved', label: 'Resolved', type: 'date', width: 80 },
  ]
};

export const RequestDetailsFields: {[viewMode: string]: BasicTableFieldsT[]} = {
  'user': [
    { value: 'status',      label: 'Request Status', type: 'status'},
    { value: 'prod_name',   label: 'Product',        type: 'string'},
    { value: 'assigned_to', label: 'Assigned To',    type: 'vendor'},
    { value: 'cat_code',    label: 'Catalog #',      type: 'string'},
    { value: 'quantity',    label: 'Quantity',       type: 'quantity'},
    { value: 'created_at',  label: 'Created',        type: 'date'},
    { value: 'updated_at',  label: 'Last Updated',   type: 'datetime'}
  ],
  'partners': [
    { value: 'status',      label: 'Request Status', type: 'status'},
    { value: 'user_id',     label: 'Customer',       type: 'email' },
    { value: 'prod_name',   label: 'Type',           type: 'string'},
    { value: 'assigned_to', label: 'Assigned To',    type: 'vendor'},
    { value: 'cat_code',    label: 'Catalog #',      type: 'string'},
    { value: 'quantity',    label: 'Quantity',       type: 'quantity'},
    { value: 'created_at',  label: 'Created',        type: 'datetime'},
    { value: 'updated_at',  label: 'Last Updated',   type: 'datetime'}
  ]
};